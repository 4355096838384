// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as farFaBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes as farFaTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

import { faFacebookF as fabFaFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faFlickr    as fabFaFlickr } from "@fortawesome/free-brands-svg-icons/faFlickr";
import { faInstagram as fabFaInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faPinterest as fabFaPinterest } from "@fortawesome/free-brands-svg-icons/faPinterest";

/**
 * Add regular icons
 */
library.add(farFaBars, farFaTimes);

/**
 * Add brands icons
 */
library.add(fabFaFacebookF, fabFaFlickr, fabFaInstagram, fabFaPinterest);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
